import React from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './img/logo-color.png';

const MenuModal = ({ 
    isOpen, 
    onRequestClose, 
    handleLogout, 
    user,
    bigFeaturedIcon,
    bigRankIcon,
    bigRankAllIcon,
    bigDiaryIcon,
    bigHomeIcon,
    bigLogoutIcon,
    changeLanguage,
    t,
    userIcon,
    settingsIcon
  }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="menu-modal"
      overlayClassName="menu-modal-overlay"
    >
      <div className="menu-modal-header">
        <img src={logo} alt="Logo" className="menu-logo" onClick={onRequestClose} />
        <button className="close-modal-button" onClick={onRequestClose}>
          &times;
        </button>
      </div>
      <div className="menu-modal-links">
        <div>
        <Link to="/">
          <Button variant="light" className="quick-button" onClick={onRequestClose} as={Link} to="/">
          <img src={bigHomeIcon} alt="Home" className="quick-icon" />
            {t('quick-links.home')}
            </Button>
        </Link>
        <Link to="/shows/best-currently">
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link} to="/best-played">
            <img src={bigRankIcon} alt="Rank" className="quick-icon" />
            {t('quick-links.best-currently')}
            </Button>
        </Link>
        <Link to="/shows/best-all-time">
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link} to="/best-all-time">
            <img src={bigRankAllIcon} alt="Rank all" className="quick-icon" />
            {t('quick-links.best-alltime')}
            </Button>
        </Link>
        </div>
        <div className="user-links">
        <Link to="/diary">
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link} to="/diary">
          <img src={bigDiaryIcon} alt="Diary" className="quick-icon" />
          {t('quick-links.your-shows')}
          </Button>
        </Link>
        <Link to="/stats-rank">
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link} to="/stats-rank">
            <img src={userIcon} alt="Rank all" className="quick-icon" />
            {t('quick-links.my-profile')}
            </Button>
        </Link>
        <Link to="/my-account">
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link} to="/my-account">
            <img src={settingsIcon} alt="Rank all" className="quick-icon" />
            {t('quick-links.my-account')}
            </Button>
        </Link>
        </div>
        <div className="settings-links">
        {user ? (
  // Jeśli użytkownik jest zalogowany, wyświetl przycisk wylogowania
  <Button 
    variant="danger" 
    className="quick-button" 
    onClick={() => {
      handleLogout(); // Wylogowanie użytkownika
      onRequestClose(); // Zamknięcie modala
      window.location.reload(); // Przeładowanie strony
    }}
  >
    <img src={bigLogoutIcon} alt="Logout" className="quick-icon quick-icon-danger" />
    {t('user.logout')}
  </Button>
) : (
  // Jeśli użytkownik nie jest zalogowany, wyświetl przycisk logowania
  <Link to="/signup">
  <Button 
    variant="primary" 
    className="quick-button" 
onClick={onRequestClose}  as={Link} to="/signup"
  >
    
    {t('navigation.sign-up')}
  </Button>
  </Link>
)}
                <div className="language-choice-mobile">
        <Link onClick={() => changeLanguage('en')}>
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link}>
            🇬🇧
          </Button>
        </Link>
        <Link onClick={() => changeLanguage('pl')}>
          <Button variant="light" className="quick-button" onClick={onRequestClose}  as={Link}>
          🇵🇱
          </Button>
        </Link>
        </div>
        </div>

      </div>
    </Modal>
  );
};

export default MenuModal;
