// Navigation.js
import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import logo from './img/logo.png'; 
import iconMenu from './img/icon-menu.svg';
import iconSearch from './img/icon-search.svg';
import { Link } from 'react-router-dom';

import SearchModal from './SearchModal';
import MenuModal from './MenuModal';


const Navigation = ({ 
  starIcon, 
  searchIcon, 
  handleSearchClick,
   handleMenuClick, 
   showSearch, 
   showMenu, 
   navSearchRef, 
   searchRef, 
   menuRef, 
   user, 
   handleLogout, 
   openSearchModal, 
   isSearchModalOpen, 
   closeSearchModal,
   bigFeaturedIcon,
   bigRankIcon,
   bigRankAllIcon,
   bigDiaryIcon,
   bigHomeIcon,
   bigLogoutIcon,
   changeLanguage,
   t,
   heartIcon,
   userIcon,
   settingsIcon
  }) => {
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

  const openMenuModal = () => {
    setIsMenuModalOpen(true);
  };

  const closeMenuModal = () => {
    setIsMenuModalOpen(false);
  };


  return (
    <Container fluid className="px-3 main-container">
      {/* Header */}
      <Row className="g-0 s-bg-primary text-white justify-content-center align-items-center py-3 mx-n3 mb-4"> {/* Usuń boczny padding i dodaj ujemny margin */}
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
          <header className="app-header mx-n3"> {/* Dodaj ujemny margin tylko dla headera */}
            <Container className="d-flex justify-content-between align-items-center px-3">
              <Link to="/" >
                <img src={logo} alt="Showify" className="logo" />
              </Link>
              <div className="navigation-icons">
                <div className="desktop-menu">
                  <Link to="/">{t('navigation.home')}</Link>
                  <Link to="/diary">{t('navigation.my-shows')}</Link>
                  <Link to="/stats-rank">{t('navigation.my-profile')}</Link>
                

                  {!user && ( // Tylko jeśli użytkownik jest zalogowany
              <Link to="/signup">
              <Button variant="primary" className="signup-desktop">{t('navigation.sign-up')}</Button>
            </Link>
        )}


        {user && ( // Tylko jeśli użytkownik jest zalogowany
          <Button 
            variant="link" 
            className="logout-desktop"
            onClick={() => {
              handleLogout(); // Wylogowanie użytkownika
              window.location.reload(); // Przeładowanie strony
            }}
          >
            {t('navigation.logout')}
          </Button>
        )}
        <div className="language-choice">
                  <Link onClick={() => changeLanguage('en')}>🇬🇧</Link>
                  <span>&nbsp;·&nbsp;</span>
                  <Link onClick={() => changeLanguage('pl')}>🇵🇱</Link>
                  </div>
         </div>
                <img src={iconSearch} alt="Search" onClick={openSearchModal} className="icon" />
                <SearchModal
  isOpen={isSearchModalOpen}
  onRequestClose={closeSearchModal}
  starIcon={starIcon}
  searchIcon={searchIcon}
  t={t}
/>
<img src={iconMenu} alt="Menu" onClick={openMenuModal} className="icon mobile-menu-icon" />
                <MenuModal 
                  isOpen={isMenuModalOpen} 
                  onRequestClose={closeMenuModal} 
                  handleLogout={handleLogout} // Przekazanie handleLogout
                  user={user}
                  bigFeaturedIcon={bigFeaturedIcon}
                  bigRankIcon={bigRankIcon}
                  bigRankAllIcon={bigRankAllIcon}
                  bigDiaryIcon={bigDiaryIcon}
                  bigHomeIcon={bigHomeIcon}
                  bigLogoutIcon={bigLogoutIcon}
                  heartIcon={heartIcon}
                  t={t}
                  changeLanguage={changeLanguage}
                  userIcon={userIcon}
                  settingsIcon={settingsIcon}
                />
              </div>
            </Container>
          </header>
        </Col>
      </Row>
    </Container>
  );
};

export default Navigation;