import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

function CookieConsent({ t }) {
  const [show, setShow] = useState(false);

  // Sprawdza status ciasteczek i pokazuje modal, jeśli nie ma zgody
  useEffect(() => {
    const consentStatus = localStorage.getItem('cookieConsent');
    if (!consentStatus) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (show) {
      // Usuwanie klasy modal-open i nadpisanie właściwości CSS
      document.body.classList.remove('modal-open');
      document.body.style.overflow = 'auto';  // Umożliwienie przewijania
    } else {
      document.body.style.overflow = '';  // Przywrócenie domyślnego stanu po zamknięciu modala
    }
  
    // Sprzątanie po odmontowaniu komponentu
    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);
  

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShow(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShow(false);
  };

  return (
    <Modal 
      show={show} 
      backdrop="false" 
      keyboard={false} 
      dialogClassName="modal-dialog-bottom"  
      className="no-blur-modal"
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
      </Modal.Header>
      <Modal.Body>
        <p>{t('cookies.content')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAccept}>
          {t('cookies.accept')}
        </Button>
        <Button variant="secondary" onClick={handleReject}>
          {t('cookies.reject')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CookieConsent;
