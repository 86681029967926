import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'; // Dodano Spinner do ładowania
import axios from 'axios';

function GoogleSignUp({ setUser, t }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Dodajemy stan dla ładowania
  const navigate = useNavigate();

  const responseGoogle = async (response) => {
    setLoading(true); // Ustawiamy loading na true, gdy akcja się rozpoczyna
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/google-login`, {
        tokenId: response.credential
      });
      setUser(res.data.user);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      localStorage.setItem('token', res.data.token);
      setError("");
      navigate('/');
    } catch (err) {
      setError("Failed to log in with Google");
    } finally {
      setLoading(false); // Niezależnie od wyniku, loading wraca na false
    }
  };

  return (
    <GoogleOAuthProvider clientId="690754398286-r6ogc9atog29jfi7shdeomt19h03ma2v.apps.googleusercontent.com">
      <Container fluid className="px-3 main-container login-page">
        <Row className="g-0 text-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="d-flex justify-content-center google-col resp-pre-xl">
            <div className="login-section text-center">
              <h1 className="header-title">{t('signup.title')}</h1>
              <p className="header-subtitle">{t('signup.subtitle')}</p>
            </div>
            {error && <p className="text-danger">{error}</p>}

            <div className="google-button-div">
              {loading ? ( // Warunek renderujący loader lub przycisk
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <GoogleLogin
                  onSuccess={responseGoogle}
                  className={'google-button'}
                  onError={() => setError(t('signup.error'))}
                  useOneTap
                />
              )}
            </div>
          </Col>
        </Row>

        <Row className="g-0 text-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
            <Link to="/">
              <Button variant="light" className="quick-button">{t('signup.back-button')}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </GoogleOAuthProvider>
  );
}

export default GoogleSignUp;
