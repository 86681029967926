import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ShowModal from './ShowModal';
import { useTranslation } from 'react-i18next';

const Diary = ({ 
  calendarIcon,
  starIcon, 
  colorCircleCalendarIcon,
  colorCircleStarIcon,
  getRatingDescription,
  renderStarRating,
  successIcon,
  currentLocale,
  isLoading,
  setIsLoading,
  chevronUpIcon,
  chevronDownIcon
}) => {
  const { t } = useTranslation();
  const [diaryEntries, setDiaryEntries] = useState([]);
  const [selectedShow, setSelectedShow] = useState(null); 
  const [sectionsToShow, setSectionsToShow] = useState(3); 
  const [expandedSections, setExpandedSections] = useState({ wishList: false, next: true }); // Początkowo wish-list zwinięta, next rozwinięta
  const navigate = useNavigate();
  const user = localStorage.getItem('token');
  const [wishListShows, setWishListShows] = useState([]);
  const [loadingButtons, setLoadingButtons] = useState({}); // Stan dla śledzenia ładowania przycisków
  const [selectedEntry, setSelectedEntry] = useState(null); // <-- Add this line



  // Funkcja pobierająca wpisy z dziennika
  useEffect(() => {
    if (!user) {
      navigate('/signup');
      return;
    }

    const fetchDiaryEntries = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setDiaryEntries(response.data);
      } catch (error) {
        console.error('Error fetching diary entries:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDiaryEntries();
  }, [user, navigate, setIsLoading]);

    // Pobieranie listy życzeń użytkownika
useEffect(() => {
  if (user) {
    const fetchWishList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        setWishListShows(response.data); // Zaktualizuj listę życzeń
      } catch (err) {
        console.error('Error fetching wish list:', err);
      }
    };

    fetchWishList();
  }
}, [user]);


const removeFromWishList = async (showId) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/remove`, { showId }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    // Znajdź i ustaw flagę `isRemoved` na true dla danego spektaklu
    setWishListShows(
      wishListShows.map(show =>
        show._id === showId ? { ...show, isRemoved: true } : show
      )
    );
  } catch (err) {
    console.error('Error removing from wish list:', err);
  }
};

const addAgainToWishList = async (showId) => {
  setLoadingButtons(prevState => ({ ...prevState, [showId]: true })); // Ustaw spinner dla konkretnego show
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/add`, { showId }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    // Zresetuj flagę `isRemoved` dla danego spektaklu
    setWishListShows(
      wishListShows.map(show =>
        show._id === showId ? { ...show, isRemoved: false } : show
      )
    );
  } catch (err) {
    console.error('Error adding to wish list again:', err);
  } finally {
    setLoadingButtons(prevState => ({ ...prevState, [showId]: false })); // Wyłącz spinner
  }
};



  // Obsługa kliknięcia w show
  const handleShowClick = (entry) => {
    setSelectedShow(entry);
    setSelectedEntry(entry); // <-- Set selectedEntry instead of selectedShow

  };

  const handleCloseModal = () => {
    setSelectedShow(null);
    setSelectedEntry(null); // Reset selectedEntry when modal closes

  };



  // Memoizowanie diarySections
  const diarySections = useMemo(() => {
    const today = new Date();
    const nextShows = diaryEntries.filter(entry => new Date(entry.dateWatched) >= today)
                                  .sort((a, b) => new Date(a.dateWatched) - new Date(b.dateWatched));

    const pastShows = diaryEntries.filter(entry => new Date(entry.dateWatched) < today)
                                  .sort((a, b) => new Date(b.dateWatched) - new Date(a.dateWatched));

    const groupedByYear = pastShows.reduce((acc, entry) => {
      const year = new Date(entry.dateWatched).getFullYear();
      if (!acc[year]) acc[year] = [];
      acc[year].push(entry);
      return acc;
    }, {});

    const sections = [];

    if (nextShows.length > 0) {
      sections.push({ title: t('my_shows.tabs.next'), id: 'next', shows: nextShows });
    }

    Object.keys(groupedByYear).sort((a, b) => b - a).forEach(year => {
      sections.push({ title: year, id: year.toString(), shows: groupedByYear[year] });
    });

    return sections;
  }, [diaryEntries, t]);

  // Zaktualizowanie stanu rozwiniętych sekcji
  useEffect(() => {
    const expanded = { next: true };

    const pastSections = diarySections.filter(section => section.id !== 'next');
    if (pastSections.length > 0) {
      expanded[pastSections[0].id] = true;
    }
    if (pastSections.length > 1) {
      expanded[pastSections[1].id] = true;
    }

    setExpandedSections(prevExpanded => ({
      ...prevExpanded,
      ...expanded
    }));
  }, [diarySections]);

  // Funkcja do rozwijania/zawijania sekcji
  const toggleSection = (id) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };


  // Funkcja ładowania większej liczby sekcji
  const loadMoreSections = () => {
    setSectionsToShow(prev => prev + 1);
  };

  // Obsługa automatycznego ładowania kolejnych sekcji przy scrollowaniu
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
        loadMoreSections();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }



  // Wstawienie sekcji wish-list
  return (
    <Container fluid className="px-3 main-container">
      <Row className="diary my-shows mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          <h1 className="page-title">{t('diary.title')}</h1>

          <div className="my-shows-buttons  mb-5">

          <Link to="/addToDiary">
            <Button variant="primary" className="add-show-button">{t('diary.add-show')}</Button>
          </Link>
          <Link to="/stats-rank">
            <Button variant="light" className="add-show-button">
              {t('my_account.stats_rank')}
            </Button>
          </Link>

          </div>

          {/* Sekcja wish-list */}
          <div className="diary-section">
            <div 
              className={`diary-section-top ${expandedSections.wishList ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('wishList')} 
              style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div>
                <h3 className="section-title">{t('my_shows.tabs.wishlist')}</h3>
                <h3 className="section-subtitle">{t('diary.show-count', { count: wishListShows.length })}</h3>

              </div>
              <div className="arrow-icon">
                {expandedSections.wishList ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
            </div>
            <div                 className={`diary-section-grid ${expandedSections.wishList ? 'expanded' : 'collapsed'}`}               >

            {expandedSections.wishList && (
              <div className="shows-grid">
                {wishListShows.length === 0 ? (
                  <p className="mt-3">{t('my_shows.wishlist.empty')}</p>
                ) : (
                  wishListShows.map((show, index) => (
                    <div key={index} className="show-card">
                      <Link to={`/shows/${show.slug}`} className="show-link">
                        <div className="show-poster">
                          <img 
                            src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} 
                            alt={show.title} 
                          />
                        </div>
                      </Link>
                      <div className="show-info">
                        <Link to={`/shows/${show.slug}`} className="show-link">
                          <h5 className="show-title">{show.title}</h5>
                        </Link>

                        {/* Renderowanie przycisków na podstawie flagi `isRemoved` */}
                        {show.isRemoved ? (
                          <Button 
                            variant="success" 
                            className="add-again-button" 
                            onClick={() => addAgainToWishList(show._id)}
                            disabled={loadingButtons[show._id]} // Zablokuj przycisk, gdy trwa ładowanie
                          >
                            {loadingButtons[show._id] ? (
                              <Spinner animation="border" size="sm" /> // Wyświetl spinner, gdy trwa ładowanie
                            ) : (
                              t('my_shows.wishlist.add_again')
                            )}
                          </Button>
                        ) : (
                          <Button 
                            variant="danger" 
                            className="remove-button" 
                            onClick={() => removeFromWishList(show._id)}
                            disabled={loadingButtons[show._id]} // Zablokuj przycisk, gdy trwa ładowanie
                          >
                            {loadingButtons[show._id] ? (
                              <Spinner animation="border" size="sm" /> // Wyświetl spinner, gdy trwa ładowanie
                            ) : (
                              t('my_shows.wishlist.remove')
                            )}
                          </Button>
                        )}

                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
            </div>
          </div>

          {/* Sekcje diary */}

          {diarySections.slice(0, sectionsToShow).map((section, index) => (
            <div key={index} className="diary-section">
              <div 
                className={`diary-section-top ${expandedSections[section.id] ? 'expanded' : 'collapsed'}`} 
                onClick={() => toggleSection(section.id)} 
                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div>
                  <h3 className="section-title">{section.title}</h3>
                  <h3 className="section-subtitle">{t('diary.show-count', { count: section.shows.length })}</h3>
                </div>
                <div className="arrow-icon">
                  {expandedSections[section.id] ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
                </div>
              </div>
              <div                 className={`diary-section-grid ${expandedSections[section.id] ? 'expanded' : 'collapsed'}`}               >
              {expandedSections[section.id] && (
                <div className="shows-grid">
                  {section.shows.map((entry, index) => (
                    <div className="show-card" key={index} onClick={() => handleShowClick(entry)}>
                      <div className="show-poster">
                        <img 
                          src={entry.show.poster.includes('http') ? entry.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                          alt={entry.show.title} 
                        />
                      </div>
                      {entry.userRating ? (
                        <div className="user-rating-badge">
                          <span>{entry.userRating}</span>
                        </div>
                      ) : (
                        <div className="user-rating-badge-star">
                          <img src={starIcon} alt="Rating" className="rating-star-badge" />
                        </div>
                      )}
                      <div className="show-info">
                        <h5 className="show-title">{entry.show.title}</h5>
                        <div className="show-date">
                          <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                          <span>{new Date(entry.dateWatched).toLocaleDateString()}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              </div>
            </div>
          ))}
        </Col>
      </Row>

      <ShowModal
        starIcon={starIcon}
        colorCircleCalendarIcon={colorCircleCalendarIcon}
        colorCircleStarIcon={colorCircleStarIcon}
        getRatingDescription={getRatingDescription}
        renderStarRating={renderStarRating}
        show={selectedShow}
        onClose={handleCloseModal}
        t={t}
        successIcon={successIcon}
        currentLocale={currentLocale}
        selectedEntry={selectedEntry} // <-- Pass selectedEntry to ShowModal

      />
    </Container>
  );
};

export default Diary;