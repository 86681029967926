import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = ( {
  t
} ) => {
  return (
    <Container fluid className="about-page px-3 my-2 mb-5 ">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={10} lg={9} xl={6} className="resp-pre-xl">
          <div>
            <h2 className="section-title">O serwisie</h2>
            <img src={`${process.env.REACT_APP_API_BASE_URL}/img/about.png`} className="about-img" alt="About" />
            <p><strong>Showify</strong> to platforma, której głównym celem jest popularyzacja teatru i zwiększenie jego przystępności.</p> 
            <p>W Showify wierzymy, że odpowiednio zorganizowany katalog spektakli z przystępnie podanymi informacjami, a przede wszystkim wskazaniem (dzięki opiniom użytkowników) sztuk wartych obejrzenia, ma szansę znacząco wpłynąć na popularyzację tej formy sztuki i rozrywki.</p>
            <p>Nasze ambicje są ogromne i krok po kroku chcemy stworzyć największą, najbardziej kompletną platformę teatralną na świecie.</p>
            <p><strong>Dołącz do naszej społeczności już dziś i stań się częścią dużej zmiany.</strong></p>
            <p>A my tymczasem wracamy do budowania Showify. Twojego codziennika teatralnego.</p>
            <p className="contact-info"><strong>kontakt@showify.pl</strong></p>
          </div>
        </Col>
      </Row>


    </Container>
  );
};

export default About;
