import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddCustomShowModal = ({ isOpen, t, isLoading, isSuccess, setIsLoading, setIsSuccess, confirmationMsg, setConfirmationMsg, currentLocale, onClose, defaultPoster, initialTitle, colorCircleCalendarIcon, colorCircleStarIcon, getRatingDescription, successIcon }) => {

  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [poster, setPoster] = useState(null);
  const [posterPreview, setPosterPreview] = useState(defaultPoster);
  const [dateWatched, setDateWatched] = useState(new Date());
  const [pendingDateWatched, setPendingDateWatched] = useState(new Date());
  const [tempRating, setTempRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(null);
  const [ratingDescription, setRatingDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [manualYear, setManualYear] = useState(new Date().getFullYear()); // Rok wprowadzony ręcznie



  useEffect(() => {
    if (isOpen) {
      setTitle(initialTitle || '');
      setPosterPreview(defaultPoster);
      setDateWatched(new Date());
      setPendingDateWatched(new Date());
      setTempRating(0);
      setRatingDescription('');
    }
  }, [isOpen, initialTitle, defaultPoster]);

  const handlePosterChange = (e) => {
    const file = e.target.files[0];
    setPoster(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPosterPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPosterPreview(defaultPoster);
    }
  };

  const handlePosterClick = () => {
    document.getElementById('poster-input').click();
  };

  const handleRatingChange = (newRating) => {
    setTempRating(newRating);
    setRatingDescription(getRatingDescription(newRating));
  };

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(null);
  };

  const handleOpenDateModal = () => {
    setIsDateModalOpen(true);
  };

  const handleCloseDateModal = () => {
    setIsDateModalOpen(false);
  };

  const handleDateChange = (date) => {
    setPendingDateWatched(date);
    setManualYear(date.getFullYear()); // Aktualizacja pola z rokiem przy wyborze daty z kalendarza

  };

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value, 10);
    if (!isNaN(year)) {
      setManualYear(year);
      const updatedDate = new Date(pendingDateWatched);
      updatedDate.setFullYear(year); // Zmieniamy rok w aktualnie wybranej dacie
      setPendingDateWatched(updatedDate); // Aktualizujemy datę w kalendarzu
    }
  };

  const handleSaveDateChange = () => {
    setDateWatched(pendingDateWatched);
    setIsDateModalOpen(false);
  };

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleRatingChange(i)}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
          className={'star-icon'}
          style={{ color: i <= (hoverRating ?? tempRating) ? '#FFC319' : '#ccc', cursor: 'pointer' }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);  // Natychmiastowe włączenie loadera
    setIsSuccess(false); // Resetowanie sukcesu, aby ukryć wcześniejsze komunikaty
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('dateWatched', dateWatched.toISOString());
    formData.append('userRating', tempRating);

    // Ustaw domyślny plakat, jeśli użytkownik nie wybrał nowego
    const posterToSave = poster ? poster : defaultPoster;
    formData.append('poster', posterToSave);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/shows/add`, formData, {
        withCredentials: true, // Jeśli używasz ciasteczek
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 201) {
        setConfirmationMsg(t('modals.add_show_modal.confirmation_message'));
        setIsSuccess(true);   // Ustawienie statusu sukcesu
      } else {
        setError(t('modals.add_custom_show_modal.error_message'));
      }
    } catch (error) {
      if (error.response) {
        setError(`${t('modals.add_custom_show_modal.server_error_message')}${error.response.data.message || t('modals.add_custom_show_modal.generic_error_message')}`);
      } else if (error.request) {
        setError(t('modals.add_custom_show_modal.no_response_error_message'));
      } else {
        setError(`${t('modals.add_custom_show_modal.generic_error_message')}${error.message}`);
      }
    } finally {
      setLoading(false);
      setIsLoading(false);  // Wyłączenie loadera po zapisaniu
    }
  };

  

  return (
    <>
      <Modal show={isOpen} onHide={onClose} className="add-custom-show-modal" centered backdrop="true">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
              ) : isSuccess ? (
                <div className="confirmation-message-modal-rem">
                <div className="confirmation-message">
                  <img src={successIcon} className="success-icon" alt="Success" />
                  <p>{confirmationMsg}</p>
        
                  <Button variant="primary" onClick={() => navigate('/diary')}>{t('modals.add_show_modal.close_button')}</Button>
        
                </div>
                </div>
      ) : (
        <>
          <Form.Group controlId="poster">
            {posterPreview && (
              <div className="modal-section">
                <div className="show-card">
                  <div className="show-poster">
                    <img
                      src={posterPreview}
                      alt={t('modals.add_custom_show_modal.poster_alt')}
                      className="modal-poster"
                      onClick={handlePosterClick}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </div>
            )}

            <Form.Label style={{ display: 'none' }}>{t('modals.add_custom_show_modal.poster_alt')}</Form.Label>
            <Form.Control
              type="file"
              id="poster-input"
              onChange={handlePosterChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
          </Form.Group>
          <h3 className="mb-3 mt-3 text-center">{t('modals.add_custom_show_modal.title')}</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>{t('modals.add_custom_show_modal.title-input')}</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>

            <h3 className="mt-4 pt-2 mb-4 text-center">{t('modals.add_custom_show_modal.diary_entry')}</h3>
            <div className="modal-subsection">
              <div className="modal-small-section">
                <div className="modal-small-section-top">
                  <div className="icon-section">
                    <img src={colorCircleCalendarIcon} alt="Calendar Icon" />
                  </div>
                  <div className="content">
                    <h6><strong>{t('modals.add_custom_show_modal.date_of_show')}</strong></h6>
                    <p className="sub">{dateWatched ? dateWatched.toLocaleDateString() : t('modals.add_custom_show_modal.no_date')}</p>
                  </div>
                </div>
                <div className="modal-small-section-bottom">
                  <Button variant="light" className="btn-small" onClick={handleOpenDateModal}>{t('modals.add_custom_show_modal.change_date_button')}</Button>
                </div>
              </div>
              <div className="modal-small-section">
                <div className="modal-small-section-top">
                  <div className="icon-section">
                    <img src={colorCircleStarIcon} alt="Star Icon" />
                  </div>
                  <div className="content">
                    <strong>{t('modals.add_custom_show_modal.rating_label')}: </strong>
                    <p className="rating-summary-p">{t('modals.add_custom_show_modal.your_rating')}: <strong>{ratingDescription} ({tempRating})</strong></p>
                  </div>
                </div>
                <div className="modal-small-section-bottom">
                  <div className="star-rating">
                    {renderStarRating(tempRating)}
                  </div>
                </div>
              </div>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button variant="primary" type="submit" disabled={loading} className="mb-0 mt-4">
              {loading ? t('modals.add_custom_show_modal.adding_button') : t('modals.add_custom_show_modal.add_show_button')}
            </Button>
            <Button variant="light" onClick={onClose} className="mb-0 mt-2">
              {t('modals.add_custom_show_modal.cancel_button')}
            </Button>
          </Form>
          </>
)}
        </Modal.Body>
      </Modal>

      <Modal show={isDateModalOpen} onHide={handleCloseDateModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="custom-datepicker">
          <div className="modal-calendar">
          <InputGroup className="mb-3">
              <InputGroup.Text>{t('modals.show_modal.year_label')}</InputGroup.Text>
              <FormControl
                type="number"
                value={manualYear}
                onChange={handleYearChange}
                aria-label="Year"
              />
            </InputGroup>
            <DatePicker
              selected={pendingDateWatched}
              onChange={handleDateChange}
              inline
              locale={currentLocale}
              calendarStartDay={1} // Ustawienie pierwszego dnia tygodnia na poniedziałek
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveDateChange}>
            {t('modals.add_custom_show_modal.save_changes_button')}
          </Button>
          <Button variant="light" onClick={handleCloseDateModal}>
            {t('modals.add_custom_show_modal.cancel_button')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCustomShowModal;
