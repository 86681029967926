import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logoGrey from './img/logo-grey.svg';  // Zaktualizuj ścieżkę do logo
import socialInstaIcon from './img/icons/social-instagram.svg';  // Zaktualizuj ścieżkę do ikony Instagram

const Footer = ( {
  t
} ) => {
  return (
    <Container fluid className="px-3 main-container footer-container pb-5">
      {/* Nowa sekcja w stopce */}
      <Row className="g-0 text-center my-5 footer-top">
        <Col xs={12}>
          <img src={logoGrey} alt="Showify Logo" className="footer-logo" />
        </Col>
        <Col xs={12}>
          <div className="footer-links">
            <a href="/about" className="footer-link">{t('footer.about')}</a>
            <a href="/about" className="footer-link">{t('footer.contact')}</a>
          </div>
        </Col>
        <Col xs={12}>
          <div className="footer-social">
            <a href="https://www.instagram.com/showify_polska/" target="_blank" rel="noopener noreferrer">
              <img src={socialInstaIcon} alt="Instagram" className="social-icon" />
            </a>
          </div>
        </Col>
      </Row>

      {/* Footer */}
      <Row className="g-0 text-center my-0 footer ">
        <Col xs={12}>
          <p>&copy; {t('footer.rights')}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
