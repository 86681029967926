import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import AddCustomShowModal from './AddCustomShowModal';
import plusIcon from './img/icons/plus.svg';



const SearchModal = ({ isOpen, onRequestClose, starIcon, searchIcon, defaultPoster, colorCircleCalendarIcon, colorCircleStarIcon, successIcon, getRatingDescription, currentLocale, isLoading, setIsLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [popularShows, setPopularShows] = useState([]);
  const [visibleShows, setVisibleShows] = useState(5); // Number of shows initially visible
  const [customModalIsOpen, setCustomModalIsOpen] = useState(false);

  
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false); // Stan dla statusu sukcesu
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const capitalizeWords = (str) => {
    return str.replace(/(^|\s)[^\s]/g, (match) => match.toUpperCase());
  };

  useEffect(() => {
    const fetchPopularShows = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/popular-shows`);
  
        // Losowo sortowanie wyników po pobraniu
        const shuffledShows = response.data.sort(() => Math.random() - 0.5);
  
        setPopularShows(shuffledShows);
      } catch (error) {
        console.error('Error fetching popular shows:', error);
      }
    };
  
    fetchPopularShows();
  }, []);

  const handleLoadMore = () => {
    setVisibleShows((prevVisibleShows) => prevVisibleShows + 5);
  };

  useEffect(() => {
    if (isOpen) {
      // Reset search term and results when modal is opened
      setSearchTerm('');
      setSearchResults([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      const fetchSearchResults = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows`, {
            params: { title: searchTerm },
          });
          setSearchResults(response.data);
        } catch (error) {
          console.error('Error searching shows:', error);
        }
      };

      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const { t } = useTranslation();


  const handleLinkClick = () => {
    // Close modal when a link is clicked
    onRequestClose();
  };

  const handleShowClick = (slug) => {
    navigate(`/shows/${slug}`);
    onRequestClose(); // Zamknij modal po kliknięciu
  };

  const handleOpenCustomModal = () => {
    setCustomModalIsOpen(true);
  };

  const handleCloseCustomModal = () => {
    setCustomModalIsOpen(false);
  };


  return (
    <>
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="search-modal"
      overlayClassName="search-modal-overlay"
      
    >
      <div className="search-modal-header-container">
      <div className="search-modal-header resp-regular">
        <InputGroup className="search-input-group">
          <FormControl
            placeholder={t('search.placeholder')} 
            aria-label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
            style={{ paddingLeft: '40px' }} // Odstęp dla ikony
          />
          <img src={searchIcon} alt="Search" className="search-icon" />
        </InputGroup>
        <button className="close-modal-button" onClick={onRequestClose}>
          &times;
        </button>
      </div>
      </div>
      {searchTerm.length > 0 && (
        <>
        <div className="search-results-container">
          <div className="search-modal-results resp-regular">
            <h3 className="section-subtitle">{t('modals.search_modal.search_results')}
            :</h3>
            {searchResults.map((show) => (
              <Link to={`/shows/${show.slug}`} className="show-link" key={show._id} onClick={handleLinkClick}>
                <div className="search-result-item">
                  <div className="show-poster">
                    <img
                      src={show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                      alt={show.title}
                      className="poster-img"
                    />
                  </div>
                  <div className="show-info">
                    <h5 className="show-title">{show.title}</h5>
                    <div className="theater-info">{show.theater}</div>
                    <div className="rating">
                    {show.avgRating > 0 && (
                      <>
                      <img src={starIcon} alt="Rating" className="rating-star" />
                      <span>  {typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}
                      </span>
                      </>
                    )}
                    </div>
                  </div>
                </div>
              </Link>
            ))}

<div 
                    className="search-result-item special-result-item"
                    onClick={handleOpenCustomModal}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="show-poster">
                      <img
                        src={defaultPoster}
                        alt="Default poster"
                        className="poster-img"
                      />
                    </div>
                    <div className="show-info">
                      <p className="theater-info">{t('add_to_diary.cant-find')}</p>
                      <p className="theater-info-add">{t('add_to_diary.add-yourself')}:</p>
                      <h5 className="show-title">{capitalizeWords(searchTerm)}</h5>
                    </div>
                    <img src={plusIcon} alt="Add new show" className="search-results-icon" />
                  </div>
          </div>
          
          </div>
        </>
      )}

      {/* Renderowanie popularnych spektakli, jeśli searchTerm jest pusty */}
      {searchTerm.length === 0 && (
        <>
        <div className="search-results-container">
          <div className="popular-shows resp-regular">
            {/* Popular Shows Section */}
            <h3 className="section-subtitle">{t('modals.search_modal.popular')}:</h3>
            <div>
              {popularShows.slice(0, visibleShows).map((show) => (
                <div
                  key={show._id}
                  className="search-result-item"
                  onClick={() => handleShowClick(show.slug)} // Użyj funkcji nawigacji
                  style={{ cursor: 'pointer' }} // Zmieniamy kursor, aby wskazywał, że element jest klikalny
                >
                  <div className="show-poster">
                    <img
                      src={show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                      alt={show.title}
                      className="poster-img"
                    />
                  </div>
                  <div className="show-info">
                    <h5 className="show-title">{show.title}</h5>
                    <div className="theater-info">{show.theater}</div>
                    <div className="rating">
                      <img src={starIcon} alt="Rating" className="rating-star" />
                      <span>  {typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {visibleShows < popularShows.length && (
              <Button onClick={handleLoadMore} className="load-more-btn">
                {t('modals.search_modal.get_more')}
              </Button>
            )}
          </div>
          </div>
        </>
      )}
      
    </Modal>

    <AddCustomShowModal
isOpen={customModalIsOpen}
onClose={handleCloseCustomModal}
defaultPoster={defaultPoster}
initialTitle={capitalizeWords(searchTerm)} // Przekazanie tytułu do modala
colorCircleCalendarIcon={colorCircleCalendarIcon} 
colorCircleStarIcon={colorCircleStarIcon} 
starIcon={starIcon} 
getRatingDescription={getRatingDescription}
successIcon={successIcon}
currentLocale={currentLocale}
t={t}
isLoading={isLoading}
setIsLoading={setIsLoading}
isSuccess={isSuccess}
setIsSuccess={setIsSuccess}
confirmationMsg={confirmationMessage}
setConfirmationMsg={setConfirmationMessage}
/>

</>

  );
};

export default SearchModal;